<template>
  <div class="page" :loading="loading" :loaded="Boolean(events)">
    <div class="wrapper">
      <div class="page-header">
        <div class="page-title">Events</div>
        <h3>Discover all events</h3>
        <h5>Find events of relevance to you</h5>
        <p>WestBIC supports an extensive range of highly innovative startups with the objective of bringing new ideas, jobs and wealth to our region.</p>
      </div>
    </div>
    <div class="events" v-if="events">
      <div class="wrapper">
        <div class="event-container" v-for="event in events" :key="event.id">
          <EventItem :event="event" />
        </div>
      </div>
    </div>
    <div class="loading">
      <div class="loader"></div>
      <div>Loading more events...</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DateFormat from "./DateFormat.vue";
import BpsImage from "./BpsImage.vue";
import EventItem from "./EventItem.vue";

export default {
  name: "Events",
  components: {
    DateFormat,
    BpsImage,
    EventItem,
  },
  data() {
    return {
      events: null,
      offset: 0,
      loading: false,
      endReached: false,
    };
  },
  computed: {
    db() {
      return this.$store.state.firebase.firestore;
    },
    ...mapGetters({
      testMode: "getTest",
    }),
  },
  watch: {
    offset() {
      this.getItems();
    },
    testMode() {
      this.getItems();
    },
  },
  methods: {
    loadEvents() {
      const container = this.$el.parentNode;
      if (
        container &&
        !this.loading &&
        !this.endReached &&
        this.scrollValue !== window.scrollY &&
        container.clientHeight - window.innerHeight <= window.scrollY
      ) {
        this.loading = true;
        this.offset += window.innerWidth > 1280 ? 6 : 4;
      }
      if (container) this.scrollValue = window.scrollY;
    },
    getItems() {
      this.loading = true;
      if (this.eventsRef) this.eventsRef();
      this.eventsRef = this.db()
        .collection(`${this.testMode ? "test_" : ""}events`)
        .orderBy("start", "desc")
        .limit(this.offset)
        .onSnapshot((events) => {
          this.events = events.docs.map((doc) => doc.data());
          this.endReached = this.events.length < this.offset;
          setTimeout(() => {
            this.loading = false;
          }, 100);
        });
    },
  },
  mounted: function() {
    window.addEventListener("scroll", this.loadEvents.bind(this), false);
    this.offset = window.innerWidth > 1280 ? 6 : 4;
  },
  beforeDestroy: function() {
    window.removeEventListener("scroll", this.loadEvents.bind(this), false);
    if (this.eventsRef) this.eventsRef();
  },
};
</script>

<style scoped>
.page-header {
  background: rgba(28, 172, 214, 0.08);
  border-radius: 16px;
  padding: 64px 96px 80px;
  margin: 64px 0;
}

.page-header .page-title {
  color: #DB735C;
}

.page-header .page-title::before {
  background-image: url(../assets/images/wb_o_4dots-o.svg);
}

.events .wrapper {
  display: flex;
  flex-wrap: wrap;
}

.event-container {
  width: 33.33333333%;
  padding: 0 8px;
  margin-bottom: 48px;
}

.event-container[large] {
  width: 100%;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 64px;
  opacity: 0;
}

.page[loading] .loading {
  opacity: 1;
}

@media screen and (max-width: 1280px) {
  .event-container {
    width: 50%;
  }
}

@media screen and (max-width: 1024px) {
  .page-header {
    margin-top: 0;
    padding: 64px;
  }
}

@media screen and (max-width: 850px) {
  .event-container {
    width: 100%;
    margin: 0 0 24px;
    padding: 0;
  }
}
</style>
